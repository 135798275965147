// must use relative path here as page-data is sometimes used in gatsby-node
import { SITE_DOMAIN } from "./constants"

export const normalizeTitle = title => {
  title = title.replaceAll(/ [l-] /g, " | ").replaceAll(/ \| 10 tours/gi, "")
  if (!title.endsWith("| 10Adventures")) {
    title += " | 10Adventures"
  }
  return title
}
export const paginateTitle = (title, pageNumber, pageCount) => {
  if (pageNumber > 1) {
    title = normalizeTitle(title)
    const lastBar = title.lastIndexOf("|")
    return (
      title.substr(0, lastBar + 1) +
      ` Page ${pageNumber} of ${pageCount} ` +
      title.substr(lastBar)
    )
  }
  return normalizeTitle(title)
}
export const paginateMetaDescription = (description, pageNumber, pageCount) => {
  if (pageNumber > 1) {
    return `${description} (Page ${pageNumber} of ${pageCount})`
  }
  return description
}
export const paginateUrl = (basePath, pageNumber) => {
  if (pageNumber > 1) {
    return `${basePath}page/${pageNumber}/`
  }
  return basePath
}
export const paginateMetaProps = (basePath, pageNumber, pageCount) => {
  const paginateMeta = {}
  if (pageNumber > 1) {
    paginateMeta.prev = paginateUrl(basePath, pageNumber - 1)
  }
  if (pageNumber < pageCount) {
    paginateMeta.next = paginateUrl(basePath, pageNumber + 1)
  }
  return paginateMeta
}

export const generateMeta = (canonical, pageSeo = {}, paginate = null) => {
  if (!canonical.startsWith(SITE_DOMAIN)) {
    canonical = `${SITE_DOMAIN}${canonical}`
  }
  const baseMeta = {
    robots: [pageSeo.metaRobotsNofollow, pageSeo.metaRobotsNoindex],
    image: pageSeo.seoImage,
    openGraph: {
      type: pageSeo.opengraphType,
      modifiedTime: pageSeo.opengraphModifiedTime,
    },
    twitter: {
      data: [],
    },
  }
  if (paginate && paginate.pageNumber && paginate.pageCount) {
    return {
      canonical: paginateUrl(canonical, paginate.pageNumber),
      ...paginateMetaProps(canonical, paginate.pageNumber, paginate.pageCount),
      description: paginateMetaDescription(
        pageSeo.metaDesc,
        paginate.pageNumber,
        paginate.pageCount
      ),
      ...baseMeta,
    }
  }
  return {
    canonical,
    description: pageSeo.metaDesc,
    ...baseMeta,
  }
}
