import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import Image, { ImagePropTypes } from "@src/components/core-image"
import { whiteTextBlackOutline } from "@src/styles/elements"

const HeaderSimpleStyled = styled.section`
  position: relative;
  min-height: 210px;
  width: 100%;
  padding: 47px 28px;
  background-color: ${props => props.theme.placeholder};
  ${props =>
    props.spacer
      ? css`
          margin-bottom: 30px;
          & + section > h2 {
            margin-top: 0;
          }
        `
      : undefined}
  ${props =>
    props.spacer === "shadow"
      ? css`
          &::after {
            content: "";
            display: block;
            position: absolute;
            z-index: -1;
            top: 100%;
            width: 100%;
            height: 0;
            box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.08);
          }
        `
      : undefined}

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  & > h1,
  & > h2,
  & > p,
  & > div {
    position: relative;
  }
  & > h1,
  & > h2,
  & > p {
    ${whiteTextBlackOutline}
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    min-height: 480px;
    padding: 86px 160px 72px;

    ${props =>
      props.spacer
        ? css`
            margin-bottom: 60px;
          `
        : undefined}
  }
`
const HeaderImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  ${props =>
    props.$imageAlign
      ? css`
          & > img {
            object-position: ${props.$imageAlign};
          }
        `
      : ""}
`
const HeaderSimple = ({ className, children, image, imageAlign, spacer }) => {
  return (
    <HeaderSimpleStyled className={className} spacer={spacer}>
      {image && (
        <HeaderImage
          $imageAlign={imageAlign}
          artDirection={{ mobile: "100vw", small: "100vw" }}
          lazyload={false}
          {...image}
        />
      )}
      {children}
    </HeaderSimpleStyled>
  )
}
HeaderSimple.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  image: PropTypes.shape({ ...ImagePropTypes }),
  imageAlign: PropTypes.string,
  spacer: PropTypes.oneOf(["margin", "shadow"]),
}
export default HeaderSimple
